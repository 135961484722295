<template>
  <div class="Users">
    <h1 class="text-h5 grey--text text-uppercase">USUÁRIOS</h1>
    <v-container>
      <v-row v-if="!users">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="users"
            sort-by="name"
            class="elevation-1 pa-2"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>LISTA DE USUÁRIOS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="addNewUser()">
                  Novo Usuário
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.id !== currentUser.id">
                <v-icon small class="mr-2" @click="editUser(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
              </div>
              <div v-else>
                <v-icon small class="mr-2" @click="selfEdit()">
                  mdi-pencil
                </v-icon>
                <v-icon small class="grey--text text--lighten-2">
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Edit Modal -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Usuário</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="editForm" v-model="isValid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="selectedUser.name"
                    :rules="[(v) => !!v || 'Campo obrigatório!']"
                    label="Nome:"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="selectedUser.role"
                    :items="roles"
                    :rules="[(v) => !!v || 'Campo obrigatório!']"
                    label="Perfil"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isLoading"
            color="grey"
            text
            @click="
              editDialog = false;
              selectedUser = {};
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="primary"
            text
            @click="doSave()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Modal -->
    <v-dialog v-model="deleteDialog" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">
          Tem certeza que deseja apagar esse usuário?
        </v-card-title>
        <v-card-text>
          <p>Essa ação não poderá ser desfeita.</p>
          <p>
            <span class="font-weight-bold">Usuário: </span>
            {{ selectedUser.name }}
            <br />
            <span class="font-weight-bold">Email: </span>
            {{ selectedUser.email }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="doDelete()">APAGAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'UsersList',

    components: {
      //
    },

    async mounted() {
      await this.$store.dispatch('fetchAllUsers');
      // console.log('this.current user', this.currentUser);
    },

    data() {
      return {
        headers: [
          {
            text: 'NOME',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'EMAIL', value: 'email' },
          { text: 'PERFIL', value: 'role' },
          { text: 'AÇÕES', value: 'actions', sortable: false },
        ],
        selectedUser: {},
        editDialog: false,
        deleteDialog: false,
        roles: [
          { text: 'Administrador', value: 'admin' },
          { text: 'Operador', value: 'op' },
        ],
        isValid: true,
      };
    },

    computed: {
      currentUser() {
        return this.$store.getters.getUser;
      },
      users() {
        return this.$store.getters.getUsers;
      },
      isLoading() {
        return this.$store.getters.getLoading;
      },
    },

    methods: {
      editUser(user) {
        // console.log(user);
        this.selectedUser = { ...user };
        this.editDialog = true;
      },

      deleteUser(user) {
        console.log(user);
        this.selectedUser = { ...user };
        this.deleteDialog = true;
      },

      async doSave() {
        if (this.selectedUser && this.$refs.editForm.validate()) {
          // console.log('save user:', this.selectedUser);
          await this.$store.dispatch(
            'updateUserNameAndRole',
            this.selectedUser
          );
          this.editDialog = false;
        }
      },

      doDelete() {
        if (this.selectedUser) {
          console.log('delete user:', this.selectedUser);
        }
      },

      selfEdit() {
        console.log('Self Edit');
      },

      addNewUser() {
        console.log('add newUser');
      },
    },
  };
</script>

<style scoped>
  .Users {
    padding: 20px;
  }
</style>
